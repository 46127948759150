//// CAMAO JS
//
//jQuery.fn.anchorAnimate = function(settings) {
//
//    settings = jQuery.extend({
//        speed : 1100
//    }, settings);
//
//    return this.each(function(){
//        var caller = this;
//        $(caller).click(function (event) {
//            event.preventDefault();
//            var elementClick = $(caller).attr("href");
//
//            var destination = $(elementClick).offset().top;
//            $("html:not(:animated),body:not(:animated)").animate({ scrollTop: destination}, settings.speed, function() {
//                window.location.hash = elementClick
//            });
//            return false;
//        })
//    })
//};
//
//function addCommas(nStr){
//    var _final = ("" + nStr).replace('.',',');
//    var _part = _final.split(',');
//
//    if (_part.length == 2){
//        if (_part[1].length == 1){
//            _final = _part[0] + "," + _part[1] + "0";
//        }
//    }
//
//    return _final;
//};
//
//$(function() {
//
//    $("a.anchor-link").anchorAnimate()
//
//    // Tabs
////    $('.tabs').tabs({'fx': { 'opacity': 'toggle' }});
//
//    //simple  helper to toggle menu states
////    $('[data-show-menu="true"]').click(function(){
////        var _menu = $(this).parent().find('.settings-menu');
////        $('.settings-menu').filter(function(a,b){
////            return _menu.get(0) != b;
////        }).hide();
////        _menu.toggle();
////    });
//
//    //simple  helper to product verlag text
//    $('[data-uid="show-publisher"]').click(function(){
//        var _menu = $(this).parent().find('.toggle-txt');
//        if (_menu.is(':visible')){
//            $(this).find("span").removeClass('open');
//        }else{
//            $(this).find("span").addClass('open');
//
//        }
//        _menu.toggle();
//    });
//
//
//    //accordion
////    $( ".accordion" ).accordion({
////        collapsible: true,
////        heightStyle: "content"
////    });
//    //Sidebar checkbox toggle
//    $('.sidebar-box .toggle-content-inner li').click(function(e){
//        if ($(e.target).prop('tagName') == "INPUT") return;
//
//        $(this).find('input').attr('checked', !$(this).find('input').attr('checked') );
//    });
//
//    $('.sidebar-box .toggle-subheadline, .sidebar-box .toggle-headline').click(function(e){
//        var _container = $(this).next();
//        var _header = $(this);
//        var _headerInput = _header.find('input');
//
//        if (!$(e.target).is(_headerInput)) {
//            if (_container.is(':visible')){
//                _header.removeClass('active');
//                _container.hide();
//
//            }else{
//                _header.addClass('active');
//                _container.show();
//            }
//        }
//
//
//        if (!_headerInput.is(':checked')) {
//            _container.find('input').attr('checked', false);
//        }
//    });
//
//
//    //datatables
//    if (typeof $('table').DataTable == "function"){
//        $('table').DataTable({
//            "language": {
//                "sEmptyTable":      "Keine Daten in der Tabelle vorhanden",
//                "sInfo":            "_START_ bis _END_ von _TOTAL_ Einträgen",
//                "sInfoEmpty":       "0 bis 0 von 0 Einträgen",
//                "sInfoFiltered":    "(gefiltert von _MAX_ Einträgen)",
//                "sInfoPostFix":     "",
//                "sInfoThousands":   ".",
//                "sLengthMenu":      "_MENU_ Einträge anzeigen",
//                "sLoadingRecords":  "Wird geladen...",
//                "sProcessing":      "Bitte warten...",
//                "sSearch":          "Suchen",
//                "sZeroRecords":     "Keine Einträge vorhanden.",
//                "oPaginate": {
//                    "sFirst":       "Erste",
//                    "sPrevious":    "Zurück",
//                    "sNext":        "Nächste",
//                    "sLast":        "Letzte"
//                },
//                "oAria": {
//                    "sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
//                    "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
//                }
//            }
//        });
//    }
//
//
//    //select2 felder
//    if (typeof $('select').select2 == "function"){
//        $('select').select2();
//    }
//
//    //cover slider
//    if (typeof $('.bxslider').bxSlider == "function"){
//        $('.bxslider').bxSlider({
//            minSlides: 4,
//            maxSlides: 8,
//            slideWidth: 235,
//            slideMargin: 0,
//            infiniteLoop : false,
//            hideControlOnEnd : true
//        });
//    }
//
//    //tooltips
//    if (typeof Tooltip == "function"){
//        new Tooltip();
//    }
//
//    //lightbox
////    if (typeof $(".lightbox").fancybox == "function"){
////        $(".lightbox").fancybox();
////
////        $('.myfancybox-close').click(function(){
////            $.fancybox.close();
////        });
////    }
//
//
//    //result list check all
//    //$('[data-uid="resultlist-check-all"]').click(function(){
//    //    if ($(this).is(':checked')){
//    //        $('.data-table input').attr('checked', true)
//    //    }else{
//    //        $('.data-table input').attr('checked', false)
//    //    }
//    //});
//
//
//    //range slider data array for date selection
//    var _sliderDates = [];
//
//    var _nextMonth = new Date(2000,0,1);
//    for(var i = 0; i < 200; i++){
//        var _month = (_nextMonth.getMonth() + 1);
//        if (("" + _month).length == 1) _month = "0" + _month;
//        _sliderDates.push(_month + "." + _nextMonth.getFullYear());
//        _nextMonth = new Date(new Date(_nextMonth).setMonth(_nextMonth.getMonth()+1));
//    }
//
//    //range slider init
//    $.each($( ".slider-range" ), function(index, slider){
//        var $slider = $(slider);
//        var _container = $slider.parent();
//        $slider.slider({
//            range: true,
//            min: parseFloat(_container.attr('data-min')),
//            max: parseFloat(_container.attr('data-max')),
//            step: parseFloat(_container.attr('data-step')),
//            values: [parseInt(_container.attr('data-min-start')), parseInt(_container.attr('data-max-start'))],
//            slide: function( event, ui ) {
//
//                if (_container.attr('data-format') == "money") {
//                    $slider.parent().find('.fl-left').html(addCommas(ui.values[0]) + ' €');
//                    $slider.parent().find('.fl-right').html(addCommas(ui.values[1]) + ' €');
//
//                }else if (_container.attr('data-format') == "date"){
//                    $slider.parent().find('.fl-left').html( _sliderDates[ui.values[ 0 ]] );
//                    $slider.parent().find('.fl-right').html( _sliderDates[ui.values[ 1 ]]);
//
//                }else{
//                    $slider.parent().find('.fl-left').html( ui.values[ 0 ] );
//                    $slider.parent().find('.fl-right').html( ui.values[ 1 ]);
//
//                }
//
//            }
//        });
//    });
//});
//
//if ($.fn.nod) {
//    var __nodProxy = $.fn.nod;
//    $.fn.nod = function(fields, settings) {
//        var nod = __nodProxy.apply(this, [fields, settings]);
//        if (fields || settings) {
//            if (nod && nod[0] && nod[0].__nod && nod[0].__nod.listeners) {
//                var _nod = nod[0].__nod;
//                $.each(_nod.listeners, function() {
//                    $(this).bind('nod_toggle', function() {
//                        this.$el.closest(_nod.get.groupSelector).toggleClass('valid', this.status && this.$el.val() != '');
//                    });
//                });
//            }
//        }
//        return nod;
//    };
//}
//else {
//    console.warn('WARNING: nod.js not found');
//}
