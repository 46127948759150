/**
 * Select2 Brasilian translation
 */
(function ($) {
    "use strict";

    $.fn.select2.locales['pt-BR'] = {
        formatNoMatches: function () { return "Sem resultados"; },
        formatInputTooShort: function (input, min) { var n = min - input.length; return "Por favor, insira " + n + " caracteres a mais"; },
        formatInputTooLong: function (input, max) { var n = input.length - max; return "Por favor, insira " + n + " caracteres a menos"; },
        formatSelectionTooBig: function (limit) { return "Você pode escolher " + limit + " it" + (limit === 1 ? "em" : "ens"); },
        formatLoadMore: function (pageNumber) { return "Carregando mais resultados…"; },
        formatSearching: function () { return "Busca…"; },
        formatMatches: function (matches) { return matches + " Resultado " + (matches > 1 ? "os" : "") + " disponív" + (matches === 1 ? "el" : "eis") + ", para navegar utilize as teclas das setas para cima e para baixo."; }
    };

    // $.extend($.fn.select2.defaults, $.fn.select2.locales['pt-BR']);
})(jQuery);