function password_forgotten() {
    $('#passwordResetModal').modal();
    return false;
}

function password_reset() {
    // empty mail adress
    if ($("#txtEMail").val() === '') {
        $("#txtEmailLabel").addClass('error');
    } else {
        var url;
        if (applicationRoot == null || applicationRoot == undefined || applicationRoot.length == 0) {
            url = window.location.origin + '/user/reset/' + $("#txtEMail").val();
        } else {
            url = window.location.origin;
            if (!_.string.startsWith(applicationRoot, '/')) {
                url += '/';
            }
            url += applicationRoot;
            if (_.string.endsWith(applicationRoot, '/')) {
                url += 'user/reset/' + $("#txtEMail").val();
            } else {
                url += '/user/reset/' + $("#txtEMail").val();
            }
        }
        $.ajax({
            url: url,
            type: 'GET',
            contentType: 'application/json;',
            dataType: 'json',
            success: function (data) {
                if (data.status === 'fail') {
                    $("#txtError").removeAttr('style');
                } else {
                    $('#passwordResetModal').modal('hide');
                    // This is just a workaround to be sure that the second modal-backtrop div is been removed.
                    $('.modal-backdrop').remove();
                }
            },
            error: function (data) {
                $('#passwordResetModal').modal('hide');
            }
        });
    }
}