Backbone.Cache = function() {
    this.store = {};
};
$.extend(Backbone.Cache.prototype, Backbone.Events, {
    set: function(key, value) {
        this.trigger("set", key, value);
        this.store[key] = value;
    },
    has: function(key) {
        var isHas = !!this.store[key];
        this.trigger("has", key, isHas);
        return isHas;
    },
    get: function(key) {
        var value = this.store[key];
        this.trigger("get", key, value);
        return value;
    },
    remove: function(key) {
        var value = this.store[key];
        this.trigger("remove", key, value);
        delete this.store[key];
        return value;
    },
    clear: function() {
        this.trigger("clear");
        this.store = {};
    }
});
